exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-project-certifaction-js": () => import("./../../../src/pages/project/certifaction.js" /* webpackChunkName: "component---src-pages-project-certifaction-js" */),
  "component---src-pages-project-postbank-js": () => import("./../../../src/pages/project/postbank.js" /* webpackChunkName: "component---src-pages-project-postbank-js" */),
  "component---src-pages-project-sensaru-js": () => import("./../../../src/pages/project/sensaru.js" /* webpackChunkName: "component---src-pages-project-sensaru-js" */),
  "component---src-pages-project-triphunt-js": () => import("./../../../src/pages/project/triphunt.js" /* webpackChunkName: "component---src-pages-project-triphunt-js" */),
  "component---src-pages-project-volksbank-js": () => import("./../../../src/pages/project/volksbank.js" /* webpackChunkName: "component---src-pages-project-volksbank-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-blogpost-js": () => import("./../../../src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */)
}

